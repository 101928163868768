var BETWEENTIMES_EVENT_EV1, CROSSTIME_EVENT_EV1, IframeApi, PlaylistIframeAPI, StopGo, Wistia, cast, clearTimeouts, clone, convertedEventNames, defaultGetHubspotUtk, doTimeout, elemBefore, elemBind, elemFromObject, elemHeight, elemInDom, elemIsHidden, elemOffset, elemRemove, elemStyle, elemUnbind, elemWidth, extractEmailFromParams, getDeep, getIframeForEvent, getLanguage, globalTrigger, injectJsonLd, isBound, isObject, isVisitorTrackingEnabled, maybeLoadAndSetupEmbedLinksThrottled, merge, oldBind, oldTrigger, oldUnbind, parseUrl, ref, ref1, ref2, ref3, ref4, ref5, removeInjectedJsonLd, scrollTop, seqId, setDeep, startHandshake, wData, wRemoveData,
  slice = [].slice,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Wistia = require('wistia_namespace.ts').Wistia;

ref = require('utilities/injectJsonLd.js'), injectJsonLd = ref.injectJsonLd, removeInjectedJsonLd = ref.removeInjectedJsonLd;

StopGo = require('utilities/stopgo.js').StopGo;

isVisitorTrackingEnabled = require('utilities/trackingConsentApi.js').isVisitorTrackingEnabled;

globalTrigger = require('utilities/globalBindAndTrigger.js').globalTrigger;

scrollTop = require('utilities/scroll.js').scrollTop;

parseUrl = require('utilities/url.js').parseUrl;

ref1 = require('utilities/wistiaData.js'), wData = ref1.wData, wRemoveData = ref1.wRemoveData;

ref2 = require('utilities/timeout-utils.js'), doTimeout = ref2.doTimeout, clearTimeouts = ref2.clearTimeouts;

seqId = require('utilities/seqid.js').seqId;

ref3 = require('utilities/obj.js'), clone = ref3.clone, cast = ref3.cast, isObject = ref3.isObject, setDeep = ref3.setDeep, getDeep = ref3.getDeep, merge = ref3.merge;

ref4 = require('utilities/elem.js'), elemBefore = ref4.elemBefore, elemBind = ref4.elemBind, elemFromObject = ref4.elemFromObject, elemHeight = ref4.elemHeight, elemInDom = ref4.elemInDom, elemIsHidden = ref4.elemIsHidden, elemOffset = ref4.elemOffset, elemRemove = ref4.elemRemove, elemStyle = ref4.elemStyle, elemUnbind = ref4.elemUnbind, elemWidth = ref4.elemWidth;

defaultGetHubspotUtk = require('../embeds/media/modules/_hubspot_v2.js').defaultGetHubspotUtk;

maybeLoadAndSetupEmbedLinksThrottled = require('../embeds/media/modules/_embed_links.js').maybeLoadAndSetupEmbedLinksThrottled;

getLanguage = require('../embeds/shared/translations.js').getLanguage;

extractEmailFromParams = require('utilities/extractEmailFromParams.ts').extractEmailFromParams;

ref5 = require('utilities/eventConstants.ts'), CROSSTIME_EVENT_EV1 = ref5.CROSSTIME_EVENT_EV1, BETWEENTIMES_EVENT_EV1 = ref5.BETWEENTIMES_EVENT_EV1;

convertedEventNames = require('utilities/eventShepherd.ts').convertedEventNames;

IframeApi = (function() {
  function IframeApi(iframe1, options) {
    this.iframe = iframe1;
    if (options == null) {
      options = {};
    }
    this._bindings = {};
    this.on('problem', (function(_this) {
      return function(problem) {
        problem.video = _this;
        return globalTrigger('problem', problem);
      };
    })(this));
    this.enableFullscreen();
    this._injectedPlugins = {};
    this.options = this.params = options;
    if (!this.plugin) {
      this.plugin = {};
    }
    this.container = this.iframe;
    this.relay = clone(Wistia.bindable);
    this.ready = new StopGo();
    this.bound = new StopGo();
    this.hasData = new StopGo();
    this._hasImpl = new StopGo();
    this.embedded = new StopGo();
    this.down = new StopGo();
    this.down(false);
    this.up = new StopGo();
    this.up(true);
    this.uuid = this.uuid || options.uuid || ("" + (this.iframe.id || seqId()));
    this.signature = options.signature || ("wistia-iframe-" + (this.uuid.replace(/"/g, '__QUOTE__').replace(/\n/g, '__NEWLINE__')));
    this.inferParamsFromSrc();
    this.monitor();
  }

  IframeApi.prototype.inferParamsFromSrc = function() {
    var ref6, ref7;
    this.src = this.iframe.src;
    this.parsedSrc = parseUrl(this.src);
    this.origWidth = parseInt(this.iframe.width || elemWidth(this.iframe), 10);
    this.origHeight = parseInt(this.iframe.height || elemHeight(this.iframe), 10);
    this.origVideoWidth = parseInt(this.parsedSrc.params.videoWidth || ((ref6 = this.parsedSrc.params.videoOptions) != null ? ref6.videoWidth : void 0) || this.origWidth, 10);
    this.origVideoHeight = parseInt(this.parsedSrc.params.videoHeight || ((ref7 = this.parsedSrc.params.videoOptions) != null ? ref7.videoHeight : void 0) || this.origHeight, 10);
    this.origPluginWidth = this.origWidth - this.origVideoWidth;
    this.origPluginHeight = this.origHeight - this.origVideoHeight;
    this.origAspectRatio = this.origVideoHeight ? this.origVideoWidth / this.origVideoHeight : 1.333;
    if (this.parsedSrc.params.videoFoam) {
      return this._videoFoam = cast(this.parsedSrc.params.videoFoam);
    }
  };

  IframeApi.prototype.init = function() {
    var key, ref6, ref7, val;
    this.inferParamsFromSrc();
    this.relay.bind('iframebound', (function(_this) {
      return function() {
        var attrs, email;
        Wistia.info('startIframeHandshake: received iframebound');
        _this._postDownVisitorTrackingEnabled();
        attrs = {
          pageUrl: location.href,
          referrerUrl: document.referrer,
          pageTitle: document.title
        };
        email = extractEmailFromParams(location.href);
        if (email) {
          attrs.email = email;
        }
        _this.postMessage('updateProperties', {
          _attrs: attrs
        });
        return _this.bound(true);
      };
    })(this));
    this.relay.bind('videoready', (function(_this) {
      return function() {
        return _this.bound(function() {
          if (!_this.looksDown()) {
            return _this.ready(true);
          }
        });
      };
    })(this));
    if (!/w_disableHandshakeOnLoad=true/.test(location.href)) {
      elemBind(this.iframe, 'load', (function(_this) {
        return function() {
          Wistia.info('startIframeHandshake: onload');
          return _this.startIframeHandshake();
        };
      })(this));
    }
    Wistia.info('startIframeHandshake: init');
    this.startIframeHandshake();
    if (!/w_disableHandshakeHack=true/.test(location.href)) {
      doTimeout(this.uuid + ".iframe_handshake_500", (function(_this) {
        return function() {
          if (!_this.bound()) {
            Wistia.info('startIframeHandshake: timeout 500');
            return _this.startIframeHandshake();
          }
        };
      })(this), 500);
      doTimeout(this.uuid + ".iframe_handshake_1000", (function(_this) {
        return function() {
          if (!_this.bound()) {
            Wistia.info('startIframeHandshake: timeout 1000');
            return _this.startIframeHandshake();
          }
        };
      })(this), 1000);
      doTimeout(this.uuid + ".iframe_handshake_3000", (function(_this) {
        return function() {
          if (!_this.bound()) {
            Wistia.info('startIframeHandshake: timeout 3000');
            return _this.startIframeHandshake();
          }
        };
      })(this), 3000);
      doTimeout(this.uuid + ".iframe_handshake_5000", (function(_this) {
        return function() {
          if (!_this.bound()) {
            Wistia.info('startIframeHandshake: timeout 5000');
            return _this.startIframeHandshake();
          }
        };
      })(this), 5000);
      doTimeout(this.uuid + ".iframe_handshake_10000", (function(_this) {
        return function() {
          if (!_this.bound()) {
            Wistia.info('startIframeHandshake: timeout 10000');
            return _this.startIframeHandshake();
          }
        };
      })(this), 10000);
    }
    ref6 = this.parsedSrc.params.plugin || {};
    for (key in ref6) {
      val = ref6[key];
      if (((ref7 = val.outsideIframe) != null ? ref7.toString() : void 0) === 'true') {
        this.addPlugin(key, val);
      }
    }
    this.monitor();
    this.ready((function(_this) {
      return function() {
        if (_this._wvideoInUrl()) {
          _this._scrollToContainer();
          return _this.play();
        }
      };
    })(this));
    return this;
  };

  IframeApi.prototype._injectJsonLd = function() {
    if (this._jsonLdId) {
      removeInjectedJsonLd(this._jsonLdId);
    }
    if (this._embedType === 'notplayable' || this._embedType === 'passwordprotected' || this._embedType === 'carouselHardWall' || this.options.seo === false) {
      return;
    }
    this._jsonLdId = "w-json-ld" + this.uuid;
    return injectJsonLd(this._jsonLdId, this._mediaData, {
      videoWidth: this.videoWidth(),
      videoHeight: this.videoHeight(),
      embedOptions: this.options
    });
  };

  IframeApi.prototype._postDownVisitorTrackingEnabled = function() {
    var e, ref6, val;
    val = ((ref6 = Wistia._visitorTracking) != null ? ref6[Wistia._visitorTrackingDomain] : void 0) != null ? isVisitorTrackingEnabled() : 'default';
    try {
      return this.iframe.contentWindow.postMessage("set-visitor-tracking-enabled=" + val + "|" + Wistia._visitorTrackingDomain, '*');
    } catch (error) {
      e = error;
    }
  };

  IframeApi.prototype._wvideoInUrl = function() {
    var url;
    url = parseUrl(location.href);
    return url.params.wvideo === this.hashedId();
  };

  IframeApi.prototype._scrollToContainer = function() {
    var offset;
    offset = elemOffset(this.container);
    return scrollTop(offset.top - 100);
  };

  IframeApi.prototype.rebuild = function() {
    var _finishRebuild, _startRebuild, oldSrc, par, placeholder;
    if ((par = this.iframe.parentNode)) {
      this.bound(false);
      this.ready(false);
      placeholder = document.createElement('span');
      placeholder.style.display = 'none';
      oldSrc = null;
      _startRebuild = (function(_this) {
        return function() {
          _this.trigger('beforerebuild');
          oldSrc = _this.iframe.src;
          par.insertBefore(placeholder, _this.iframe);
          par.removeChild(_this.iframe);
          return _this.iframe.src = 'javascript:return false;';
        };
      })(this);
      _finishRebuild = (function(_this) {
        return function() {
          _this.iframe.src = oldSrc;
          par.insertBefore(_this.iframe, placeholder.nextSibling);
          par.removeChild(placeholder);
          placeholder = null;
          _this._state = 'beforeplay';
          return _this.trigger('afterrebuild');
        };
      })(this);
      _startRebuild();
      _finishRebuild();
    }
    return this;
  };

  IframeApi.prototype.remove = function() {
    this._hasImpl(false);
    this.hasData(false);
    this.ready(false);
    this.embedded(false);
    this.container.wistiaApi = 'removed';
    Wistia.eventLoop.remove(this.uuid);
    clearTimeouts(this.uuid);
    return this.bound((function(_this) {
      return function() {
        var finishRemoving;
        finishRemoving = function() {
          var par;
          elemRemove(_this._foamDummyElem);
          if (_this.iframe && (par = _this.iframe.parentNode)) {
            _this.iframe.wistiaApi = null;
            _this.iframe.wistiaIframeApiProxy = null;
            par.removeChild(_this.iframe);
            _this.iframe.src = 'javascript:return false;';
            _this.iframe = _this.container = null;
          }
          _this.trigger('down');
          clearTimeouts(_this.uuid);
          Wistia.eventLoop.remove(_this.uuid);
          _this.ready.setQueue([]);
          _this.hasData.setQueue([]);
          _this._hasImpl.setQueue([]);
          _this.trigger('afterremove');
          _this._bindings = {};
          wRemoveData("iframe_api." + _this.signature);
          return _this.unbind;
        };
        _this.relay.bind('_afterremove', finishRemoving);
        return _this.postMessage('remove');
      };
    })(this));
  };

  IframeApi.prototype.reinitializePlugins = function() {
    return this.postMessage('reinitializePlugins');
  };

  IframeApi.prototype.monitor = function() {
    var didFoam, lastHeight, lastParentWidth, lastWidth;
    lastParentWidth = this.width();
    lastWidth = elemWidth(this.iframe);
    lastHeight = elemHeight(this.iframe);
    didFoam = false;
    return this.embedded((function(_this) {
      return function() {
        _this._injectJsonLd();
        return Wistia.eventLoop.add(_this.uuid + ".monitor", 500, function() {
          var heightNow, newHeight, newVideoHeight, newVideoWidth, newWidth, parentWidthNow, ref6, ref7, ref8, ref9, widthNow;
          if (_this.up() && _this.looksDown()) {
            _this._stateBeforeDown = _this._state;
            _this.pause();
            _this.ready(false);
            _this.down(true);
            _this.up(false);
            _this.trigger('down');
          } else if (_this.down() && _this.looksUp()) {
            _this.down(false);
            _this.up(true);
            _this.ready(true);
            if (_this._stateBeforeDown === 'playing') {
              _this.play();
            }
            if (_this._stateBeforeDown == null) {
              widthNow = _this.width();
              heightNow = _this.height();
              _this.width(widthNow + 1);
              _this.height(heightNow + 1);
              _this.width(widthNow);
              _this.height(heightNow);
            }
            _this.trigger('up');
          }
          if (_this.hasVideoFoam() && _this.up() && _this.looksUp()) {
            if (_this._parentBoxSizing == null) {
              _this._parentBoxSizing = elemStyle(_this.iframe.parentNode, 'box-sizing');
            }
            if (_this._parentBoxSizing === 'border-box') {
              if (!_this._foamDummyElem) {
                _this._foamDummyElem = elemFromObject({
                  "class": 'wistia_video_foam_dummy',
                  'data-source-container-id': _this.container.id,
                  style: {
                    border: 0,
                    display: 'block',
                    height: 0,
                    margin: 0,
                    padding: 0,
                    position: 'static',
                    visibility: 'hidden',
                    width: 'auto'
                  }
                });
                elemBefore(_this.iframe, _this._foamDummyElem);
              }
              parentWidthNow = elemWidth(_this._foamDummyElem);
            } else {
              parentWidthNow = elemWidth(_this.iframe.parentNode);
            }
            if (lastParentWidth !== parentWidthNow || !didFoam) {
              didFoam = true;
              newWidth = parentWidthNow;
              newVideoWidth = newWidth - _this.extraWidth();
              newVideoHeight = _this.heightForWidth(newVideoWidth);
              newHeight = newVideoHeight + _this.extraHeight();
              if (((ref6 = _this.videoFoam()) != null ? ref6.maxHeight : void 0) && newHeight > _this.videoFoam().maxHeight) {
                newHeight = _this.videoFoam().maxHeight;
                newVideoHeight = newHeight - _this.extraHeight();
                newVideoWidth = _this.widthForHeight(newVideoHeight);
                newWidth = newVideoWidth + _this.extraWidth();
              }
              if (((ref7 = _this.videoFoam()) != null ? ref7.maxWidth : void 0) && newWidth > _this.videoFoam().maxWidth) {
                newWidth = _this.videoFoam().maxWidth;
                newVideoWidth = newWidth - _this.extraWidth();
                newVideoHeight = _this.heightForWidth(newVideoWidth);
                newHeight = newVideoHeight + _this.extraHeight();
              }
              if (((ref8 = _this.videoFoam()) != null ? ref8.minHeight : void 0) && newHeight < _this.videoFoam().minHeight) {
                newHeight = _this.videoFoam().minHeight;
                newVideoHeight = newHeight - _this.extraHeight();
                newVideoWidth = _this.widthForHeight(newVideoHeight);
                newWidth = newVideoWidth + _this.extraWidth();
              }
              if (((ref9 = _this.videoFoam()) != null ? ref9.minWidth : void 0) && newWidth < _this.videoFoam().minWidth) {
                newWidth = _this.videoFoam().minWidth;
                newVideoWidth = newWidth - _this.extraWidth();
                newVideoHeight = _this.heightForWidth(newVideoWidth);
                newHeight = newVideoHeight + _this.extraHeight();
              }
              _this.width(newWidth).height(newHeight);
              doTimeout(_this.uuid + ".delayed_resize", function() {
                return _this.width(newWidth).height(newHeight);
              }, 1000);
              return lastParentWidth = parentWidthNow;
            }
          }
        });
      };
    })(this));
  };

  IframeApi.prototype.hasVideoFoam = function() {
    if (this.videoFoam() != null) {
      return this.videoFoam();
    } else {
      return /videoFoam=true/.test(this.src);
    }
  };

  IframeApi.prototype.stopMonitoring = function() {
    return Wistia.eventLoop.remove(this.uuid + ".monitor");
  };

  IframeApi.prototype.looksDown = function() {
    return !this.iframe || !elemInDom(this.iframe) || elemIsHidden(this.iframe);
  };

  IframeApi.prototype.looksUp = function() {
    return !this.looksDown();
  };

  IframeApi.prototype._trigger = function() {
    var args, event;
    event = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
    return doTimeout(this.uuid + ".trigger." + event, ((function(_this) {
      return function() {
        return _this.trigger.apply(_this, [event].concat(slice.call(args)));
      };
    })(this)));
  };

  IframeApi.prototype.play = function() {
    this.ready((function(_this) {
      return function() {
        return _this.postMessage('play');
      };
    })(this));
    return this;
  };

  IframeApi.prototype.playerLanguage = function(l) {
    if (l != null) {
      return this.embedded((function(_this) {
        return function() {
          if (l !== _this._playerLanguage.code) {
            _this.ready(function() {
              return _this.postMessage('playerLanguage', l);
            });
            return _this;
          }
        };
      })(this));
    } else {
      return getLanguage(this._playerLanguage.code) || getLanguage('en-US');
    }
  };

  IframeApi.prototype.playerColor = function(c) {
    if (c != null) {
      return this.embedded((function(_this) {
        return function() {
          if (c !== _this._playerColor) {
            _this.ready(function() {
              return _this.postMessage('playerColor', c);
            });
            return _this;
          }
        };
      })(this));
    } else {
      return this._playerColor;
    }
  };

  IframeApi.prototype.pause = function() {
    this.ready((function(_this) {
      return function() {
        return _this.postMessage('pause');
      };
    })(this));
    return this;
  };

  IframeApi.prototype.time = function(t) {
    if (t != null) {
      this.ready((function(_this) {
        return function() {
          return _this.postMessage('time', t);
        };
      })(this));
      return this;
    } else {
      return this._time || 0;
    }
  };

  IframeApi.prototype.volume = function(v) {
    if (v != null) {
      this.ready((function(_this) {
        return function() {
          return _this.postMessage('volume', v);
        };
      })(this));
      return this;
    } else {
      return this._volume || 0;
    }
  };

  IframeApi.prototype.isMuted = function() {
    return !!this._isMuted;
  };

  IframeApi.prototype.mute = function() {
    this.ready((function(_this) {
      return function() {
        return _this.postMessage('mute');
      };
    })(this));
    return this;
  };

  IframeApi.prototype.unmute = function() {
    this.ready((function(_this) {
      return function() {
        return _this.postMessage('unmute');
      };
    })(this));
    return this;
  };

  IframeApi.prototype.setEmail = function(e) {
    return this.email(e);
  };

  IframeApi.prototype.email = function(e) {
    var ref6;
    if (e === void 0) {
      return this._email || ((ref6 = this.params) != null ? ref6.trackEmail : void 0) || null;
    } else {
      this.bound((function(_this) {
        return function() {
          return _this.postMessage('email', e);
        };
      })(this));
      return this._email = e;
    }
  };

  IframeApi.prototype.foreignData = function(fd) {
    if (fd === void 0) {
      return this._foreignData || null;
    } else if (fd === null) {
      this.bound((function(_this) {
        return function() {
          return _this.postMessage('foreignData', fd);
        };
      })(this));
      return this._foreignData = null;
    } else {
      if (!isObject(fd)) {
        throw 'foreignData can only be an Object';
      }
      fd = clone(fd);
      this.bound((function(_this) {
        return function() {
          return _this.postMessage('foreignData', fd);
        };
      })(this));
      return this._foreignData = fd;
    }
  };

  IframeApi.prototype.height = function(h) {
    if (h != null) {
      h = parseInt(h, 10);
      if (!this.hasVideoFoam()) {
        this.bound((function(_this) {
          return function() {
            return _this.postMessage('height', h);
          };
        })(this));
      }
      this.iframe.style.height = h + "px";
      this.iframe.height = h;
      return this;
    } else {
      if (elemIsHidden(this.iframe)) {
        if (this.iframe.style.height != null) {
          return parseInt(this.iframe.style.height, 10);
        } else {
          return parseInt(this.iframe.height, 10);
        }
      } else {
        return elemHeight(this.iframe);
      }
    }
  };

  IframeApi.prototype.width = function(w) {
    if (w != null) {
      w = parseInt(w, 10);
      if (!this.hasVideoFoam()) {
        this.bound((function(_this) {
          return function() {
            return _this.postMessage('width', w);
          };
        })(this));
      }
      this.iframe.style.width = w + "px";
      this.iframe.width = w;
      return this;
    } else {
      if (elemIsHidden(this.iframe)) {
        if (this.iframe.style.width != null) {
          return parseInt(this.iframe.style.width, 10);
        } else {
          return parseInt(this.iframe.width, 10);
        }
      } else {
        return elemWidth(this.iframe);
      }
    }
  };

  IframeApi.prototype.videoHeight = function(h) {
    var match;
    if (h != null) {
      h = parseInt(h, 10);
      if (!this.hasVideoFoam()) {
        this.bound((function(_this) {
          return function() {
            return _this.postMessage('videoHeight', h);
          };
        })(this));
      }
      this.embedded((function(_this) {
        return function() {
          var newHeight;
          if (_this._height != null) {
            newHeight = h + _this._height - _this._videoHeight;
          } else {
            newHeight = h + _this.origPluginHeight;
          }
          _this.iframe.style.height = newHeight + "px";
          return _this.iframe.height = newHeight;
        };
      })(this));
      return this;
    } else {
      if (this._videoHeight > 0) {
        return this._videoHeight;
      } else {
        if (match = this.src.match(/videoHeight=(\d+)/)) {
          return parseInt(match[1], 10);
        } else {
          return elemHeight(this.iframe);
        }
      }
    }
  };

  IframeApi.prototype.videoWidth = function(w) {
    var match;
    if (w != null) {
      w = parseInt(w, 10);
      if (!this.hasVideoFoam()) {
        this.bound((function(_this) {
          return function() {
            return _this.postMessage('videoWidth', w);
          };
        })(this));
      }
      this.embedded((function(_this) {
        return function() {
          var newWidth;
          if (_this._width != null) {
            newWidth = w + _this._width - _this._videoWidth;
          } else {
            newWidth = w + _this.origPluginWidth;
          }
          _this.iframe.style.width = newWidth + "px";
          return _this.iframe.width = newWidth;
        };
      })(this));
      return this;
    } else {
      if (this._videoWidth > 0) {
        return this._videoWidth;
      } else {
        if (match = this.src.match(/videoWidth=(\d+)/)) {
          return parseInt(match[1], 10);
        } else {
          return elemWidth(this.iframe);
        }
      }
    }
  };

  IframeApi.prototype.extraHeight = function() {
    if (this.origPluginHeight) {
      return this.origPluginHeight;
    } else if (this._height) {
      return this._height - this._videoHeight;
    } else {
      return 0;
    }
  };

  IframeApi.prototype.extraWidth = function() {
    if (this.origPluginWidth) {
      return this.origPluginWidth;
    } else if (this._width) {
      return this._width - this._videoWidth;
    } else {
      return 0;
    }
  };

  IframeApi.prototype.heightForWidth = function(width) {
    return Math.round(width / this.aspect());
  };

  IframeApi.prototype.widthForHeight = function(height) {
    return Math.round(height * this.aspect());
  };

  IframeApi.prototype.aspect = function() {
    return this.origAspectRatio || 1.333;
  };

  IframeApi.prototype.addPlugin = function(name, options) {
    if (options == null) {
      options = {};
    }
    if (options['on'] !== false) {
      if (!this.injectedPlugin(name)) {
        if (options.outsideIframe) {
          Wistia.plugin._inject(this, name, options);
        } else {
          this.bound((function(_this) {
            return function() {
              return _this.postMessage('addPlugin', name, options);
            };
          })(this));
        }
        this._injectedPlugins[name] = true;
      }
    }
    return this;
  };

  IframeApi.prototype.videoFoam = function(forReal) {
    if (forReal != null) {
      return this._videoFoam = forReal;
    } else {
      return this._videoFoam;
    }
  };

  IframeApi.prototype.hasPlugin = function(name) {
    var ref6;
    return !!(this._injectedPlugins[name] || ((ref6 = this.parsedSrc.params.plugin) != null ? ref6[name] : void 0));
  };

  IframeApi.prototype.injectedPlugin = function(name) {
    return !!this._injectedPlugins[name];
  };

  IframeApi.prototype.duration = function() {
    return this._duration || null;
  };

  IframeApi.prototype.state = function() {
    return this._state || 'beforeplay';
  };

  IframeApi.prototype.name = function() {
    return this._name || '';
  };

  IframeApi.prototype.lastPlayInfo = function() {
    return this._lastPlayInfo || {};
  };

  IframeApi.prototype.hashedId = function() {
    return this._hashedId || this.parsedSrc.path[this.parsedSrc.path.length - 1];
  };

  IframeApi.prototype.embedType = function() {
    return this._embedType;
  };

  IframeApi.prototype.eventKey = function() {
    return this._eventKey || null;
  };

  IframeApi.prototype.visitorKey = function() {
    return this._visitorKey || null;
  };

  IframeApi.prototype.percentWatched = function() {
    return this._percentWatched || 0;
  };

  IframeApi.prototype.getEventKey = function() {
    return this.eventKey();
  };

  IframeApi.prototype.getVisitorKey = function() {
    return this.visitorKey();
  };

  IframeApi.prototype.startIframeHandshake = function() {
    return this.postMessage('startIframeHandshake', this.signature);
  };

  IframeApi.prototype.postMessage = function() {
    var args, e, message, method;
    method = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
    try {
      if (!this.iframe.contentWindow.postMessage) {
        return;
      }
    } catch (error) {
      e = error;
      return;
    }
    message = {
      signature: this.signature,
      method: method,
      args: args
    };
    message = JSON.stringify(message);
    return this.iframe.contentWindow.postMessage(message, '*');
  };

  IframeApi.prototype.getHubspotUtk = function() {
    return defaultGetHubspotUtk().then((function(_this) {
      return function(hs_utk) {
        return _this.postMessage('updateProperties', {
          hs_utk: hs_utk
        });
      };
    })(this));
  };

  IframeApi.prototype.updateProperties = function(props) {
    var key, results, val;
    results = [];
    for (key in props) {
      val = props[key];
      if (isObject(val)) {
        results.push(this[key] = merge({}, this[key], val));
      } else {
        results.push(this[key] = val);
      }
    }
    return results;
  };

  IframeApi.prototype.enableFullscreen = function() {
    var allowAttr, ref6, ref7, ref8;
    allowAttr = (ref6 = this.iframe) != null ? ref6.getAttribute('allow') : void 0;
    if (!allowAttr) {
      if ((ref7 = this.iframe) != null) {
        ref7.setAttribute('allow', 'fullscreen');
      }
    }
    if (allowAttr && allowAttr.indexOf('fullscreen') === -1) {
      return (ref8 = this.iframe) != null ? ref8.setAttribute('allow', allowAttr + "; fullscreen") : void 0;
    }
  };

  return IframeApi;

})();

Wistia.mixin(IframeApi.prototype, Wistia.bindable);

oldTrigger = IframeApi.prototype.trigger;

IframeApi.prototype.trigger = function() {
  var args, event, ref6;
  event = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
  oldTrigger.call.apply(oldTrigger, [this, event].concat(slice.call(args)));
  return (ref6 = window.wistiaEmbeds) != null ? ref6.trigger.apply(ref6, [event, this].concat(slice.call(args))) : void 0;
};

oldBind = IframeApi.prototype.bind;

IframeApi.prototype.bind = function() {
  var args, bindArgs, event, stringifiedFuncs;
  event = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
  if (event === CROSSTIME_EVENT_EV1 || event === BETWEENTIMES_EVENT_EV1 || convertedEventNames[event]) {
    stringifiedFuncs = args.map((function(_this) {
      return function(arg) {
        if (typeof arg === 'function') {
          return arg.toString();
        } else {
          return arg;
        }
      };
    })(this));
    bindArgs = ['bind', event].concat(stringifiedFuncs);
    IframeApi.prototype.postMessage.apply(this, bindArgs);
  } else {
    oldBind.call.apply(oldBind, [this, event].concat(slice.call(args)));
  }
  return this;
};

oldUnbind = IframeApi.prototype.unbind;

IframeApi.prototype.unbind = function() {
  var args, event, stringifiedFuncs, unbindArgs;
  event = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
  if (event === CROSSTIME_EVENT_EV1 || event === BETWEENTIMES_EVENT_EV1 || convertedEventNames[event]) {
    stringifiedFuncs = args.map((function(_this) {
      return function(arg) {
        if (typeof arg === 'function') {
          return arg.toString();
        } else {
          return arg;
        }
      };
    })(this));
    unbindArgs = ['unbind', event].concat(stringifiedFuncs);
    IframeApi.prototype.postMessage.apply(this, unbindArgs);
  } else {
    oldUnbind.call.apply(oldUnbind, [this, event].concat(slice.call(args)));
  }
  return this;
};

Wistia.mixin(IframeApi.prototype, Wistia.logHelpers);

IframeApi.prototype._logPrefix = function() {
  var ref6;
  return [this.constructor.name, this.hashedId() || 'no hashedId', (ref6 = this.container) != null ? ref6.id : void 0, this.uuid];
};

Wistia.IframeApi = IframeApi;

PlaylistIframeAPI = (function(superClass) {
  extend(PlaylistIframeAPI, superClass);

  function PlaylistIframeAPI() {
    PlaylistIframeAPI.__super__.constructor.apply(this, arguments);
    this.relay.bind('boundcurrentvideo', (function(_this) {
      return function() {
        return _this._currentVideo = new IframeApi(_this.iframe, {
          uuid: _this.uuid + "-current-video",
          signature: _this.signature + "-current-video"
        }).init();
      };
    })(this));
  }

  PlaylistIframeAPI.prototype.embed = function(sectionIndex, videoIndex) {
    return this.ready((function(_this) {
      return function() {
        return _this.postMessage('embed', sectionIndex, videoIndex);
      };
    })(this));
  };

  PlaylistIframeAPI.prototype.play = function(sectionIndex, videoIndex) {
    return this.ready((function(_this) {
      return function() {
        return _this.postMessage('play', sectionIndex, videoIndex);
      };
    })(this));
  };

  PlaylistIframeAPI.prototype.currentVideo = function() {
    return this._currentVideo;
  };

  return PlaylistIframeAPI;

})(IframeApi);

Wistia.PlaylistIframeAPI = PlaylistIframeAPI;

if (!window.wistiaBindIframes) {
  window.wistiaBindIframes = function() {
    var handle, i, iframe, iframes, j, len, len1, newHandles, ref6, results;
    iframes = document.getElementsByTagName('iframe');
    newHandles = [];
    for (i = 0, len = iframes.length; i < len; i++) {
      iframe = iframes[i];
      if ((!iframe.wistiaApi || iframe.wistiaApi instanceof Wistia.IframeApiProxy) && (/wistia_embed/.test(iframe.className) || iframe.name === 'wistia_embed')) {
        iframe.wistiaApi = new IframeApi(iframe);
        wData("iframe_api." + iframe.wistiaApi.signature, iframe.wistiaApi);
        iframe.wistiaApi.init();
        newHandles.push(iframe.wistiaApi);
        window.wistiaApi = iframe.wistiaApi;
      } else if ((!iframe.wistiaApi || iframe.wistiaApi instanceof Wistia.IframeApiProxy) && (/wistia_playlist/.test(iframe.className) || iframe.name === 'wistia_playlist')) {
        iframe.wistiaApi = new PlaylistIframeAPI(iframe);
        wData("iframe_api." + iframe.wistiaApi.signature, iframe.wistiaApi);
        iframe.wistiaApi.init();
        newHandles.push(iframe.wistiaApi);
        window.wistiaApi = iframe.wistiaApi;
      }
    }
    if ((ref6 = window.wistiaEmbeds) != null) {
      ref6.bindHandles();
    }
    maybeLoadAndSetupEmbedLinksThrottled();
    results = [];
    for (j = 0, len1 = newHandles.length; j < len1; j++) {
      handle = newHandles[j];
      handle.trigger('initembed');
      if (handle.container) {
        results.push(handle.container.dispatchEvent(new CustomEvent('initembed', {
          detail: {
            api: handle
          }
        })));
      } else {
        results.push(void 0);
      }
    }
    return results;
  };
}

wistiaBindIframes();

getIframeForEvent = function(event) {
  var i, iframe, iframes, len;
  iframes = document.getElementsByTagName('iframe');
  for (i = 0, len = iframes.length; i < len; i++) {
    iframe = iframes[i];
    if (event && event.source === iframe.contentWindow) {
      return iframe;
    }
  }
  return null;
};

isBound = function(iframe) {
  var ref6, ref7, ref8;
  return ((ref6 = iframe.wistiaApi) != null ? typeof ref6.bound === "function" ? ref6.bound() : void 0 : void 0) || ((ref7 = iframe.wistiaApi) != null ? (ref8 = ref7._currentVideo) != null ? typeof ref8.bound === "function" ? ref8.bound() : void 0 : void 0 : void 0);
};

startHandshake = function(iframe) {
  var iframeApi, ref6;
  iframeApi = ((ref6 = iframe.wistiaApi) != null ? ref6._currentVideo : void 0) || iframe.wistiaApi;
  return typeof iframeApi.startIframeHandshake === "function" ? iframeApi.startIframeHandshake() : void 0;
};

if (!window._inWistiaIframe && !window.wistiaDispatch && !window._wistiaIframeShim) {
  if (window.wistiaDispatch == null) {
    window.wistiaDispatch = function(event) {
      var handle, iframe, message;
      if (event.data === 'new-wistia-iframe') {
        wistiaBindIframes();
        if (iframe = getIframeForEvent(event)) {
          if (iframe.wistiaApi && !isBound(iframe)) {
            Wistia.info('startIframeHandshake: from new-wistia-iframe');
            return startHandshake(iframe);
          }
        }
      } else {
        if (typeof event.data === 'string' && /wistia-iframe-/.test(event.data)) {
          message = JSON.parse(event.data);
        } else {
          message = event.data;
        }
        if (/wistia-iframe-/.test(message.signature)) {
          if (handle = wData('iframe_api')[message.signature]) {
            if (!handle.iframe || (event.source !== window && event.source !== handle.iframe.contentWindow)) {
              return;
            }
            if (message.method === 'relay.trigger') {
              return handle.relay.trigger.apply(handle.relay, message.args);
            } else if (message.method === 'play' && (Wistia.detect.iphone || Wistia.detect.ipad || Wistia.detect.android)) {

            } else if (message.method === 'addPlugin' && event.source !== window) {

            } else {
              if (IframeApi.prototype.hasOwnProperty(message.method) || handle.hasOwnProperty(message.method)) {
                return handle[message.method].apply(handle, message.args);
              } else {
                return typeof console !== "undefined" && console !== null ? console.log("Ignored " + message.method + " call; not owner of property.") : void 0;
              }
            }
          } else {
            return typeof console !== "undefined" && console !== null ? console.log("Ignored " + message.method + " call, no handle for " + message.signature) : void 0;
          }
        }
      }
    };
  }
  elemBind(window, 'message', wistiaDispatch);
}

Wistia._initializers.initIframeApi = function() {
  wistiaBindIframes();
  if (!window._inWistiaIframe && !window.wistiaDispatch && !window._wistiaIframeShim) {
    if (window.wistiaDispatch == null) {
      window.wistiaDispatch = function(event) {
        var handle, message;
        if (event.data === 'new-wistia-iframe') {
          return wistiaBindIframes();
        } else {
          if (typeof event.data === 'string' && /wistia-iframe-/.test(event.data)) {
            message = JSON.parse(event.data);
          } else {
            message = event.data;
          }
          if (/wistia-iframe-/.test(message.signature)) {
            if (handle = wData('iframe_api')[message.signature]) {
              if (message.method === 'relay.trigger') {
                return getDeep(handle, message.method).apply(handle.relay, message.args);
              } else if (message.method === 'play' && (Wistia.detect.iphone || Wistia.detect.ipad || Wistia.detect.android)) {

              } else {
                return getDeep(handle, message.method).apply(handle, message.args);
              }
            } else {
              return typeof console !== "undefined" && console !== null ? console.log("Ignored " + message.method + " call, no handle for " + message.signature) : void 0;
            }
          }
        }
      };
    }
    return elemBind(window, 'message', wistiaDispatch);
  }
};

Wistia._destructors.destroyIframeApi = function() {
  if (window.wistiaDispatch) {
    elemUnbind(window, 'message', window.wistiaDispatch);
  }
  return window.wistiaDispatch = null;
};

Wistia._initializers.initIframeApi();

Wistia.bind('ev1initend', Wistia._initializers.initIframeApi);

Wistia.bind('ev1destroystart', Wistia._destructors.destroyIframeApi);
